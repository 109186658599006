import React, { startTransition, useState } from 'react';
import { type CfAccountInfo } from '@chainflip/rpc/types';
import { abbreviate } from '@chainflip/utils/string';
import { isMetamaskSnapsSupported } from '@chainsafe/metamask-polkadot-adapter';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { MacScrollbar } from 'mac-scrollbar';
import MetamaskLogo from '@/shared/assets/svg/wallet-logos/metamask.svg';
import PolkadotJsLogo from '@/shared/assets/svg/wallet-logos/polkadotjs.svg';
import SubwalletLogo from '@/shared/assets/svg/wallet-logos/subwallet.svg';
import { Link } from '@/shared/components/atoms/Link';
import { DashboardModal } from '@/shared/components/dashboards/Modal';
import LoadingSpinner from '@/shared/components/LoadingSpinner';
import { Tooltip } from '@/shared/components/molecules/Tooltip';
import {
  type InjectedAccountWithMeta,
  usePolkadot,
  POLKADOT_JS_SOURCE,
  SUBWALLET_JS_SOURCE,
  MM_SNAP_SOURCE,
} from '@/shared/hooks/usePolkadot';
import { CloseIcon, WarningTriangleIcon, InformationIcon } from '@/shared/icons/large';
import LogoSquareIcon from '@/shared/icons/LogoSquare';
import { accountInfoRpcKey } from '@/shared/queryKeys';
import { FLIP_SYMBOL, TokenAmount } from '@/shared/utils';
import makeRpcRequest from '@/shared/utils/rpc';

const SelectRow = ({
  children,
  onClick,
  tooltip,
  disabled = false,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  tooltip?: string;
  disabled?: boolean;
}) => (
  <Tooltip content={tooltip} disabled={!tooltip} tooltipClassName="w-[400px]">
    <button
      className={classNames(
        'flex w-full items-center rounded-md border border-cf-gray-4 p-4 transition ease-out',
        'enabled:bg-cf-gray-3-5 enabled:hover:bg-cf-gray-4 [&>div]:disabled:opacity-40',
      )}
      type="button"
      disabled={!onClick || disabled}
      onClick={onClick}
    >
      <div className="w-full">{children}</div>
    </button>
  </Tooltip>
);

const SelectPjsAccountList = ({
  onSelect,
  accountType,
  selectedExtension,
}: {
  onSelect: (selectedAccount: InjectedAccountWithMeta) => void;
  accountType: 'broker' | 'liquidity_provider';
  selectedExtension: SupportedWallet;
}) => {
  const { injectedAccounts } = usePolkadot();
  const extensionAccounts = injectedAccounts.filter((a) => a.meta.source === selectedExtension);

  const accountInfos = useQueries({
    queries: extensionAccounts.map((acct) => ({
      queryKey: accountInfoRpcKey(acct.idSs58),
      queryFn: () => makeRpcRequest('cf_account_info', acct.idSs58),
      select(data: CfAccountInfo) {
        return {
          ...data,
          flip_balance: TokenAmount.fromAsset(data.flip_balance, 'Flip'),
        };
      },
    })),
  });

  return (
    <div className="flex h-full flex-col justify-between gap-6">
      <MacScrollbar skin="dark" className="max-h-[400px] overflow-y-scroll">
        <div className="flex flex-col gap-2">
          {extensionAccounts
            .map((account, index) => {
              const accountInfo = accountInfos[index].data;

              return {
                account,
                accountInfo,
                disabled: accountInfo?.role !== accountType && accountInfo?.role !== 'unregistered',
              };
            })
            .sort((a, b) => {
              if (a.disabled && !b.disabled) return 1;
              if (!a.disabled && b.disabled) return -1;

              return b.accountInfo?.flip_balance?.cmp(a.accountInfo?.flip_balance ?? 0) ?? 0;
            })
            .map(({ account, accountInfo, disabled }) => (
              <SelectRow onClick={() => onSelect(account)} key={account.idSs58} disabled={disabled}>
                <div className="flex w-full flex-col gap-0.5 text-14">
                  <div className="flex items-center justify-between">
                    <span className="text-cf-white">{account.meta.name}</span>
                    {accountInfo?.flip_balance && (
                      <div className="flex items-center gap-1">
                        <LogoSquareIcon height={8} width={12} />
                        <span className="font-aeonikMono text-cf-white">
                          {accountInfo?.flip_balance?.toPreciseFormattedString()}
                        </span>
                        <span className="font-aeonikMono text-12 text-cf-light-2">
                          {FLIP_SYMBOL}
                        </span>
                      </div>
                    )}
                  </div>
                  <span className="text-left font-aeonikMono text-12 text-cf-light-2">
                    {abbreviate(account.idSs58, 12)}
                  </span>
                </div>
              </SelectRow>
            ))}
        </div>
      </MacScrollbar>
      <div className="flex items-center gap-2 rounded-md border border-cf-orange-1/20 bg-cf-orange-4 p-3 text-14 text-cf-orange-1">
        <div>
          <WarningTriangleIcon width="24" height="24" />
        </div>
        <div>
          <span className="font-bold">Ledger wallets</span> are not compatible with the Chainflip
          State Chain
        </div>
      </div>
    </div>
  );
};

const SelectMetamaskRow = ({
  isConnecting,
  onSelect,
}: {
  isConnecting: boolean;
  onSelect: () => void;
}) => {
  const isMetamaskInstalled = Boolean(typeof window !== 'undefined' && window.ethereum?.isMetaMask);
  const { data: isSnapSupported, isPending: isSnapSupportedLoading } = useQuery({
    queryKey: ['isMetamaskSnapsSupported'],
    queryFn: isMetamaskSnapsSupported,
  });
  const isFakeMetamask = isMetamaskInstalled && !isSnapSupportedLoading && !isSnapSupported;

  let text;
  let tooltip;
  let onClick;
  let spinner;
  let disabled = false;

  if (!isMetamaskInstalled) {
    text = 'Install Metamask';
    onClick = () => window.open('https://metamask.io/download/');
  } else if (isFakeMetamask) {
    text = 'Incompatible Metamask';
    tooltip =
      'Metamask is not installed or overwritten by a different wallet extension. Try to disable conflicting wallet extensions.';
    disabled = true;
  } else if (isConnecting) {
    text = 'Approve connection';
    spinner = true;
  } else {
    text = 'Connect Metamask';
    onClick = onSelect;
  }

  return (
    <SelectRow onClick={onClick} disabled={disabled} tooltip={tooltip}>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="flex w-full items-center gap-2 text-14 text-cf-white">
          <div className="flex w-[24px] justify-center">
            <MetamaskLogo />
          </div>
          <div>{text}</div>
        </div>
        {spinner && <LoadingSpinner className="!h-[16px] !w-[16px]" />}
      </div>
    </SelectRow>
  );
};

const walletInfo = {
  [POLKADOT_JS_SOURCE]: {
    installUrl: 'https://polkadot.js.org/extension/',
    displayName: 'Polkadot{.js}',
    logo: <PolkadotJsLogo />,
  },
  [SUBWALLET_JS_SOURCE]: {
    installUrl: 'https://www.subwallet.app/download.html',
    displayName: 'SubWallet',
    logo: (
      <div className="h-6">
        <SubwalletLogo className="origin-top scale-[0.25]" />
      </div>
    ),
  },
} as const;

type SupportedWallet = keyof typeof walletInfo;

const SelectPjsRow = ({
  onSelect,
  name,
}: {
  onSelect: (value: SupportedWallet) => void;
  name: SupportedWallet;
}) => {
  const { injectedExtensions, isInitializingExtensions } = usePolkadot();
  const isExtInstalled = injectedExtensions.some((ext) => ext.name === name);

  let text;
  let tooltip;
  let onClick;
  let spinner;
  let disabled = false;
  const { displayName, installUrl, logo } = walletInfo[name];

  if (isInitializingExtensions) {
    text = `Initializing ${displayName}`;
    tooltip =
      'Detecting installed Polkadot wallets. Make sure to handle connection requests in your wallet extensions.';
    disabled = true;
    spinner = true;
  } else if (!isExtInstalled) {
    text = `Install ${displayName}`;
    onClick = () => window.open(installUrl);
  } else {
    text = `Connect ${displayName}`;
    onClick = () => onSelect(name);
  }

  return (
    <SelectRow onClick={onClick} disabled={disabled} tooltip={tooltip}>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="flex w-full items-center gap-2 text-14 text-cf-white">
          <div className="flex w-[24px] justify-center">{logo}</div>
          <div>{text}</div>
        </div>
        {spinner && <LoadingSpinner className="!h-[16px] !w-[16px]" />}
      </div>
    </SelectRow>
  );
};

const SelectWalletList = ({
  onSelectWallet,
  onSelectAccount,
}: {
  onSelectWallet: (selectedWallet: SupportedWallet) => void;
  onSelectAccount: (selectedAccount: InjectedAccountWithMeta) => void;
}) => {
  const { injectedAccounts, connectSnap } = usePolkadot();
  const { mutate: connectMetamaskSnap, isPending: isConnectingMetamaskSnap } = useMutation({
    mutationFn: async () => {
      let snapAccount = injectedAccounts.find((a) => a.meta.source === MM_SNAP_SOURCE);
      if (!snapAccount) {
        snapAccount = await connectSnap();
      }
      onSelectAccount(snapAccount);
    },
  });

  if (isConnectingMetamaskSnap) {
    return (
      <div className="flex flex-col gap-6">
        <SelectMetamaskRow onSelect={connectMetamaskSnap} isConnecting={isConnectingMetamaskSnap} />
        <div className="flex items-center gap-2 rounded-md border border-cf-blue-2/20 bg-cf-blue-5 p-3 text-14 text-cf-blue-2">
          <div>
            <InformationIcon width="24" height="24" />
          </div>
          <div>
            We use Metamask Snaps to install a Polkadot wallet and setup your State Chain account.{' '}
            <Link
              href="https://snaps.metamask.io/snap/npm/chainsafe/polkadot-snap/"
              target="_blank"
              className="underline"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <SelectMetamaskRow onSelect={connectMetamaskSnap} isConnecting={isConnectingMetamaskSnap} />
      <SelectPjsRow onSelect={onSelectWallet} name={SUBWALLET_JS_SOURCE} />
      <SelectPjsRow onSelect={onSelectWallet} name={POLKADOT_JS_SOURCE} />
    </div>
  );
};

export default function ConnectPolkadotWalletModal({
  open,
  onClose,
  onCompleted,
  accountType,
}: {
  open: boolean;
  onClose: () => void;
  onCompleted: (selectedAccount: InjectedAccountWithMeta) => void;
  accountType: 'broker' | 'liquidity_provider';
}) {
  const { selectAccount } = usePolkadot();
  const [selectedExtension, setSelectedExtension] = useState<SupportedWallet>();

  const onSelectAccount = (account: InjectedAccountWithMeta) => {
    startTransition(() => {
      selectAccount(account);
      onCompleted(account);
    });
  };

  return (
    <DashboardModal
      open={open}
      onClose={onClose}
      afterLeave={() => setSelectedExtension(undefined)}
    >
      <div className="bg-holy-radial-gray-3-60 relative w-[367px] rounded-md border border-cf-gray-4 backdrop-blur-[6px]">
        <div className="flex h-full flex-col space-y-7 p-5">
          <div className="flex items-center justify-between">
            <div className="cf-gray-gradient font-aeonikMedium text-20">Connect Wallet</div>
            <button type="button" onClick={onClose} className="outline-cf-gray-5">
              <CloseIcon />
            </button>
          </div>
          {!selectedExtension ? (
            <SelectWalletList
              onSelectWallet={setSelectedExtension}
              onSelectAccount={onSelectAccount}
            />
          ) : (
            <SelectPjsAccountList
              onSelect={onSelectAccount}
              accountType={accountType}
              selectedExtension={selectedExtension}
            />
          )}
        </div>
      </div>
    </DashboardModal>
  );
}
